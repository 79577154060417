import styled from 'styled-components';
import testimonialVerticalBg from '../../../images/vertical-testimonial-bg.svg';
import { font } from '../../atoms/typography';

export const VerticalContainer = styled.div`
  position: relative;
  .sticky-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @media (max-width: 767.98px) {
      position: unset;
    }
  }
  .sticky-wrapper {
    background-image: url(${testimonialVerticalBg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 792px;
    min-height: calc(100vh - 95px);
    overflow: hidden;
    position: sticky;
    top: 95px;
    @media (max-width: 1199.98px) {
      top: 64px;
      min-height: calc(100vh - 64px);
    }
    @media (max-width: 991.98px) {
      overflow: hidden;
    }
    @media (max-width: 767.98px) {
      height: auto;
      min-height: unset;
    }
  }
  .heading {
    margin-bottom: 0;
    color: #fff;
    pointer-events: none;
  }
  .subhead-wrapper {
    display: flex;
    align-items: center;
    pointer-events: none;
    z-index: 2;
    @media (max-width: 991px) {
      margin-bottom: 64px;
      margin-top: 64px;
    }
    @media (max-width: 767.98px) {
      margin-top: 96px;
    }
    .subhead {
      margin-bottom: 0;
      color: #fff;
      p {
        ${font('display', 'xxl', '700')}
        margin-bottom: 0;
        @media (min-width: 992px) and (max-width: 1200px) {
          font-size: 43.95px;
        }
        @media (max-width: 991px) {
          font-size: 35.16px;
        }
        &:last-of-type {
          color: #00c9a1;
        }
      }
    }
  }
  .testimonial-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    position: relative;
    margin: 50% 0;
    @media (min-width: 992px) and (max-width: 1366px) {
      margin-right: 24px;
    }
    @media (max-width: 991px) {
      margin-top: auto;
      align-items: center;
      width: auto;
      position: relative;
    }
    @media (max-width: 767.98px) {
      height: auto;
      margin-top: -300px;
    }
  }
  .testimonial-card {
    position: relative;
    width: 100%;
    max-width: 684px;
    background: #1834f3;
    box-shadow: 0 8px 24px -4px rgba(19, 48, 205, 0.1),
      0px 8px 8px -4px rgba(19, 48, 205, 0.04);
    border-radius: 15px;
    color: #fff;
    padding: 32px;
    margin-bottom: 32px;
    /* transition: opacity 0.1s; */
    @media (min-width: 992px) and (max-width: 1199px) {
      max-width: 580px;
    }
    p {
      margin-bottom: 0;
    }
    .title {
      ${font('display', 'md', '700')}
      color: var(--white);
      margin-bottom: 24px;
      @media (max-width: 991px) {
        font-size: 22.5px;
      }
      @media (max-width: 575px) {
        font-size: 18px;
      }
    }
    .quote {
      font-size: 22.5px;
      line-height: 150%;
      letter-spacing: -0.27px;
      color: var(--white);
      margin-bottom: 16px;
      @media (max-width: 575px) {
        font-size: 18px;
      }
    }
    .person {
      ${font('text', 'sm', '400')}
      color: #c8d0ff;
      .info {
        .name {
          span {
            font-weight: 700;
          }
        }
      }
    }
  }
  .swiper-wrapper {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 96px;
    opacity: 0;
    .swipe-slider-item {
      padding: 0 15px;
    }
    &.d-shown {
      opacity: 1;
      transition: opacity 0.3s;
      transition-delay: 0.3s;
    }
  }
  .dots-container {
    padding: 0 15px;
    .carousel-arrow {
      display: inline-flex;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      border: 2px solid #fff;
      color: #fff;
      margin-left: 8px;
    }
  }
  .slick-dots {
    li {
      color: #fff;
      button {
        &:before {
          font-size: 15px;
          color: #fff;
        }
      }
      &.slick-active {
        color: #fff;
        button {
          &:before {
            color: #fff;
          }
        }
      }
    }
  }
  .scroll-content {
    &:after {
      content: '';
    }
  }
`;
